.searchPage {
  height: 0;
  flex: 1;
  display: flex;
  .scrollList {
    flex: 1;
    border-left: 1px solid $border-color;
    > div {
      display: flex;
      flex-direction: column;
      .bankItem {
        flex: 0 0 20%;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        padding: 0 70px;
        .logo {
          height: 60px;
          width: 60px;
          object-fit: contain;
        }
        .text {
          padding-left: 20px;
          .bankName,
          .country {
            line-height: 1;
            color: #444444;
            text-transform: capitalize;
          }
          .bankName {
            font-weight: 600;
            font-size: 23px;
          }
          .country {
            font-weight: 400;
            font-size: 17px;
          }
        }
        &:hover {
          background: whitesmoke;
          zoom: 1.05;
        }
      }
    }
  }
}
