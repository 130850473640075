.virtualProspectusPage {
  display: flex;
  flex: 1;
  .sideBar {
    flex: 0 0 320px;
    border-right: 1px solid $border-color;
    position: relative;
    .openClose {
      z-index: 1;
      border: 1px solid $border-color;
      background: white;
      position: absolute;
      top: 20px;
      right: 0;
      transform: translateX(50%);
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .pathList {
      > div {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        .pathItem {
          flex: 0 0 90px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          opacity: 0.6;
          img {
            width: 45px;
            height: 45px;
            object-fit: contain;
            margin-right: 15px;
          }
          span {
            width: 0;
            flex: 1;
            font-weight: 600;
            font-size: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &.true {
            opacity: 1;
          }
        }
      }
    }
    &.true {
      max-width: 90px;
      .pathList {
        > div {
          .pathItem {
            padding: 0;
            justify-content: center;
            img {
              width: 50px;
              height: 50px;
              margin: auto;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .pageContent {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .headLogoMobile {
      display: none;
    }
    .headLogo {
      flex: 0 0 14%;
      max-height: 14%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 50%;
      }
    }
    .tabs {
      height: 60px;
      display: flex;
      border-bottom: 1px solid $border-color;
      .tabItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex: 0 0 25%;
        font-size: 17px;
        font-weight: 500;
        position: relative;
        opacity: 0.4;
        &::before {
          margin: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 0;
          content: "";
          height: 2px;
          background: $primary-color;
          transition: all 500ms ease;
        }
        &.true {
          opacity: 1;
          font-size: 22px;
          font-weight: 600;
          &::before {
            width: 100%;
          }
        }
      }
    }
    .vPAnalysisContent {
      height: 0;
      flex: 1;
      display: flex;
      .coinContent {
        flex: 0 0 49%;
        display: flex;
        flex-direction: column;
        border-right: 1px solid $border-color;
        .coinDetail {
          border-bottom: 1px solid $border-color;
          padding: 40px 10%;
          .coinHead {
            height: 50px;
            display: flex;
            align-items: center;
            font-weight: 600;
            img {
              height: 32px;
              margin-right: 8px;
            }
            .coinName {
              font-size: 30px;
            }
            .coinValue {
              margin-left: auto;
              font-size: 26px;
            }
          }
          .desc {
            margin-top: 10px;
            line-height: 1.8;
          }
        }
        .vpCandleChart {
          height: 0;
          flex: 1;
        }
        .footer {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          border-top: 1px solid $border-color;
          height: 65px;
        }
      }
      .statsContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        .statsHead {
          display: flex;
          justify-content: space-between;
          padding: 40px 40px 40px 100px;
          .stats {
            order: 1;
            .title {
              font-size: 24px;
              font-weight: 600;
            }
            .date {
              font-size: 14px;
            }
            &.true {
              order: 0;
              .title {
                font-size: 29px;
              }
              .date {
                font-size: 16px;
              }
            }
          }
        }
        .statsContainer {
          flex: 1;
          position: relative;
          perspective: 1000px;
          transform-style: preserve-3d;
          overflow: hidden;
          .content {
            margin-left: 100px;
            margin-top: 2px;
            width: 70%;
            height: 95%;
            position: absolute;
            left: 0;
            right: 0;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            transition: transform 0.4s ease;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
            transform: translate3d(50%, 0, -200px);
            background: white;
            &.true {
              z-index: 1;
              transform: translate3d(0, 0, 0);
            }
            padding: 40px;
            .supplyList {
              height: 60px !important;
              > div {
                display: flex;
                .supply {
                  min-width: fit-content;
                  border: 1px solid $border-color;
                  display: flex;
                  flex: 0 0 32%;
                  margin-right: 2%;
                  padding: 0 15px;
                  align-items: center;
                  font-size: 21px;
                  font-weight: 600;
                  opacity: 0.6;
                  img {
                    height: 55%;
                    width: auto;
                    margin-right: 10px;
                  }
                  &.true {
                    opacity: 1;
                  }
                }
              }
            }
            .headValue {
              margin-top: 30px;
              color: $primary-color;
              font-weight: 600;
              font-size: 26px;
            }
            .donutNcard {
              flex: 1;
              display: flex;
              .donutWrap {
                flex: 0 0 50%;
                display: flex;
                .donutSvg {
                  margin: auto;
                  width: 80%;
                  height: auto;
                }
              }
              .donutCards {
                width: 0;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .detailCard {
                  border: 1px solid $border-color;
                  border-left-width: 8px;
                  flex: 0 0 90px;
                  margin-bottom: 20px;
                  padding-left: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  .sold {
                    font-size: 15px;
                  }
                  .value {
                    font-size: 21px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-height: 1050px) {
    zoom: 0.95;
  }
  @include xxxl {
    zoom: 0.8;
  }
  @include xxl {
    zoom: 0.7;
  }
  @include xl {
    zoom: 0.6;
  }
  @include lg {
    zoom: 0.5;
  }
  @include md {
    zoom: 0.6;
    .sideBar {
      display: none !important;
    }
    .pageContent {
      .headLogoMobile {
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $border-color;
        img {
          height: 85%;
          width: auto;
        }
      }
      .headLogo {
        display: none;
      }
      .tabs {
        display: none;
      }
      .vPAnalysisContent {
        flex-direction: column;
        .coinContent {
          flex: unset;
          .vpCandleChart {
            display: none;
          }
          .footer {
            display: none;
          }
        }
        .statsContent {
          .statsHead {
            padding: 40px;
          }
          .statsContainer {
            .content {
              margin-left: 40px;
              width: 80%;
              .supplyList {
                > div {
                  .supply {
                    flex: 0 0 40%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
