.BuyCard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  border: none !important;
}
.contents {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #232931;
  border: none !important;
}

.label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 22px;
}
.labelActive {
  font-size: 12px;
}

.inpWraper {
  border: 1px solid #999999;
  border-radius: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 425px;
  height: 66px;
}
.inputcontainer {
  background-color: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.5;
}
.aactive {
  opacity: 1;
  color: #ffffff;
}
.coinContainer {
  border: none;
  padding-right: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #ffffff;
}

.btBuyButton {
  width: 425px;
  height: 66px;
  background: linear-gradient(85.77deg, #cf1a38 -13.34%, #283991 131.26%);
  border-radius: 35px;
  border: none !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 10px;
}
/* hover */
.btBuyButton:hover {
  transform: scale(1.05);
}
.miniButton {
  width: 91px;
  height: 39px;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
}
.actives {
  background: linear-gradient(85.77deg, #283991 -13.34%, #cf1a38 131.26%);
}
