.tradeInspectorWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  .overlay-ti {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .ti-content {
    z-index: 2;
    margin: auto;
    width: 90%;
    max-width: 780px;
    min-height: 500px;
    background: white;
    display: flex;
    flex-direction: column;
    .ti-head {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary-color;
      height: 60px;
      font-size: 26px;
      font-weight: 600;
      color: white;
      padding: 0 40px;
      line-height: 1;
      img {
        height: 30px;
        filter: brightness(0) invert(1);
        margin-right: 10px;
      }
    }
    .ti-detail {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0 12%;
      .ti-row {
        .label {
          color: $primary-color;
          font-size: 16px;
        }
        .value {
          color: $primary-color;
          font-size: 24px;
          font-weight: 600;
          white-space: nowrap;
        }
        &.horiz {
          display: flex;
          flex-wrap: wrap;
          .flex-50 {
            flex: 0 0 50%;
          }
        }
      }
    }
    .ti-detailSmart {
      display: flex;
      flex-direction: column;
      // justify-content: space-evenly;
      padding: 40px 60px;
      color: $primary-color;
      &.true {
        flex: 1;
      }
      .appNameBalance {
        text-align: center;
        font-size: 16px;
      }
      .appNameBalanceValue {
        text-align: center;
        font-size: 42px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.inp {
          margin-bottom: 20px;
        }
        .inpVal {
          margin: 5px 0;
          line-height: 1;
          position: relative;
          display: flex;
          border: none;
          text-align: center;
          width: 6ch;
          .boxCoin {
            padding: 4px 10px;
            font-weight: 500;
            position: absolute;
            left: 100%;
            bottom: 15px;
            font-size: 16px;
            border: 1px solid $border-color;
            display: flex;
            .list {
              background: white;
              top: 100%;
              left: 0;
              right: 0;
              position: absolute;
              height: 120px;
              overflow: auto;
              .listItem {
                height: 45px;
                border: 1px solid $border-color;
                border-bottom: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover {
                  background: #f2f2f2;
                }
              }
            }
          }
        }
        .btnGenarate {
          cursor: pointer;
          padding: 6px 20px;
          font-size: 18px;
          font-weight: 600;
          margin-top: 6px;
          border: 1px solid $border-color;
          &:hover {
            background: whitesmoke;
          }
        }
      }
      .lblRow,
      .valueRow {
        display: flex;
        justify-content: space-between;
      }
      .lblRow {
        margin-top: 10px;
        font-size: 15px;
        font-weight: 500;
      }
      .valueRow {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
  .tradeInspector {
    width: fit-content;
    min-height: 350px;
  }
  &.smart {
    .ti-content {
      width: 90%;
      height: 80%;
      max-width: 900px;
      max-height: 800px;
      .ti-detailSmart {
        justify-content: space-evenly;
        .sideLevels {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
  &.reserve {
    .ti-content {
      width: 90%;
      height: 80%;
      max-width: 900px;
      max-height: 800px;
      .ti-reseerveRequirement {
        position: relative;
        padding: 60px 80px;
        padding-bottom: 0;
        flex: 1;
        color: $primary-color;
        display: flex;
        flex-direction: column;
        .subTitle {
          font-size: 24px;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
        }
        .coinListWrapper {
          height: 60px !important;
          .coinList {
            display: flex;
            .coin {
              height: 100%;
              width: 60px;
              flex: 0 0 60px;
              border: 1px solid $border-color;
              margin-right: 10px;
              display: flex;
              background: #f2f2f2;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              img {
                height: 80%;
                width: 80%;
                object-fit: contain;
              }
            }
          }
        }
        .coinDetail {
          margin-top: 30px;
          font-size: 19px;
          border: 1px solid $border-color;
          height: 55px;
          padding: 0 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
        }
        .cardListWrapper {
          margin-top: 30px;
          height: 0 !important;
          flex: 1;
          .cardList {
            display: flex;
            flex-direction: column;
            .cardItems {
              border: 1px solid $border-color;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;
              .rowOne {
                border-bottom: 1px solid $border-color;
                padding-left: 40px;
                height: 50px;
                display: flex;
                align-items: center;
                font-weight: 600;
                .tier {
                  flex: 1;
                }
                .value {
                  border-left: 1px solid $border-color;
                  height: 100%;
                  flex: 0 0 18%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              .rowTwo {
                border-bottom: 1px solid $border-color;
                padding: 0 40px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .valueLabel {
                  .value {
                    font-size: 12px;
                  }
                  .label {
                    font-size: 20px;
                    font-weight: 600;
                  }
                }
              }
              .rowThree {
                height: 50px;
                display: flex;
                .asset {
                  height: 100%;
                  padding: 0 40px;
                  display: flex;
                  align-items: center;
                  border-right: 1px solid $border-color;
                  position: relative;
                  .list {
                    background: white;
                    top: 100%;
                    left: 0;
                    right: 0;
                    position: absolute;
                    height: 120px;
                    overflow: auto;
                    .listItem {
                      height: 45px;
                      border: 1px solid $border-color;
                      border-bottom: none;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                      &:hover {
                        background: #f2f2f2;
                      }
                    }
                  }
                }
                .finalRate {
                  flex: 1;
                  padding: 0 40px;
                  background: #f2f2f2;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-weight: 600;
                  .rate {
                    display: flex;
                    flex: 1;
                    padding: 0 30px;
                    justify-content: space-between;
                    .label {
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
        .btnScroll {
          position: absolute;
          bottom: 0;
          height: 30px;
          width: 30px;
          background: #e7e7e7;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.left {
            left: 0;
          }
          &.right {
            right: 0;
          }
        }
      }
    }
  }
}
