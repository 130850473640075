.navbar {
  height: 90px;
  border-bottom: 1px solid $border-color;
  display: flex;
  padding: 0 150px;
  .crypto-img {
    cursor: pointer;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 0.4s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  .navBtn {
    cursor: pointer;
    height: 45px;
    flex: 0 0 9%;
    border: 1px solid $primary-color;
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    &::before {
      content: "";
      background-color: white;
      width: 100%;
      height: 40px;
      position: absolute;
      left: -100%;
      transform: rotateZ(45deg);
    }
    &:hover {
      background: $primary-color;
      color: white;
      &::before {
        transition: all 0.4s ease;
        left: 100%;
      }
    }
  }
  @include md {
    padding: 0 20px;
    .crypto-img {
      margin: 0 auto;
    }
    .navBtn {
      display: none;
    }
  }
}
