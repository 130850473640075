.dropcontainer{
    padding-top: 70px;
    padding-bottom: 15px;
    width: 100vw;
}

.alldropdowns{
    /* height: calc(100vh - 110px); */
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    transition: all ease .3s;
    background-color: white;
}

.eachdropdown{
    border: none;
    outline: none;
    color:#000;
    width: 33.33333333333333333vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
    cursor: pointer;
    transition: all .3s ease;
}

.eachdropdown:hover{
    box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
    border-radius: 25px;
}

.droptitle{
    margin-top: 14px;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #1F304F;
}

.droptext{
    margin-top: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 45px;
    color: #1F304F;
}

.divide-text{
    width: 100vw;
    height: 76px;
    font-weight: 500;
    font-size: 30px;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    padding-left: 2.5vw;
    color: #343C5B;
}
@media only screen and (min-width: 0px) and (max-width: 900px) {
    .eachdropdown{
        width: 100vw;
        align-items: center;
        text-align: center;
        padding-left: 0px;
    }
}