.transactionCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 527px !important;
  height: 596px !important;
  padding: 10px;
  border-radius: 35px;
  border: none !important;
  background-color: #232931;
  color: #f5f5f5 !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 10px;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px;
  color: #f5f5f5;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  border: none !important;
  line-height: 60px;
}
/* tab active */
.active {
  border-bottom: 3px solid #cf1a38;
}
