.landing-main{
    background: #000;
    padding-top: 70px;
    height: 100vh;
    width: 100vw;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
}

.landing-head{
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100vw;
    padding: 0px 10vw;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    background: linear-gradient(90.17deg, #CF1A38 -2.89%, #E7258E 106.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.landing-text{
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100vw;
    padding: 0px 10vw;
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    color: #FFFFFF;
}

.landing-all-icons{
    width: 100vw;
    padding: 0px 10vw;
    margin-top: 10vh;
    display: flex;
    justify-content: space-between;
}

.landing-border{
    width: 1px;
    background-color: #FFFFFF;
}

.landing-icon-box{
    padding: 7vh 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
}

.landing-icon-box:hover{
    transform: scale(1.2);
}

.landing-sec2{
    width: 100vw;
    background-color: #FFFFFF;
    padding: 7vh 2.5vw;
    display: flex;
    justify-content: space-between;
}

.sec2-left{
    width: 50vw;
}

.sec2-left-head{
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #18191D;
}

.sec2-left-desc{
    margin: 10px 0px;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    color: #18191D;
}

.sec2-line{
    text-decoration: underline;
    cursor: pointer;
    transition: all ease .2s;
}

.sec2-line:hover{
    transform: scale(1.1)
}

.sec2-left-btn{
    background: linear-gradient(85.77deg, #CF1A38 -13.34%, #283991 131.26%);
    border-radius: 35px;
    padding: 13px 40px;
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
    width: fit-content;
    margin-top: 15px;
    cursor: pointer;
    transition: all ease .2s;
}

.sec2-left-btn:hover{
    transform: scale(1.1)
}

.landing-sec3{
    width: 100vw;
    background-color: #18191D;
    padding: 7vh 2.5vw;
    display: flex;
    justify-content: space-between;
}

.sec3-left{
    width: 50vw;
}

.sec3-left-head{
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #FFFFFF;
}

.sec3-left-desc{
    margin: 10px 0px;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    color: #FFFFFF;
}

.sec3-line{
    text-decoration: underline;
    cursor: pointer;
    transition: all ease .2s;
}

.sec3-line:hover{
    transform: scale(1.1)
}

.sec3-left-btn{
    background: linear-gradient(85.77deg, #CF1A38 -13.34%, #283991 131.26%);
    border-radius: 35px;
    padding: 13px 40px;
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
    width: fit-content;
    margin-top: 15px;
    cursor: pointer;
    transition: all ease .2s;
}

.sec3-left-btn:hover{
    transform: scale(1.1)
}

.landing-mob-btns{
    display: none;
}

@media only screen and (min-width:0px) and (max-width:900px) {
    .landing-main{
        height: auto;
    }

    .landing-head{
        font-weight: 700;
        font-size: 34px;
        line-height: 55px;
    }

    .landing-text{
        font-weight: 600;
        font-size: 22px;
        line-height: 36px;
    }

    .landing-sec2{
        overflow: hidden;
    }

    .landing-sec3{
        overflow: hidden;
    }

    .landing-all-icons{
        display: none;
    }

    .landing-mob-btns{
        margin: 20px 0px;
        width: 100vw;
        padding: 30px 5vw;
        display: flex;
        flex-direction: column;
    }

    .landing-btn1{
        border: 1px solid #FFFFFF;
        border-radius: 35px;
        height: 50px;
        background-color: #18191D;
        color: white;
        font-weight: 700;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease .2s;
    }
    .landing-btn1:hover{
        transform: scale(1.1);
    }

    .landing-btn1-img{
        margin-right: 10px;
        height: 28px;
        margin-top: 5px;
    }

    .landing-btn2{
        margin: 15px 0px;
        border-radius: 35px;
        background: linear-gradient(85.77deg, #CF1A38 -13.34%, #283991 131.26%);
        color: white;
        font-weight: 700;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        cursor: pointer;
        transition: all ease .2s;
        outline: none;
        border: none;
    }
    .landing-btn2:hover{
        transform: scale(1.1);
    }

    .landing-sec2, .landing-sec3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
    }

    .sec2-left, .sec3-left{
        width: 90vw;
        margin: auto;
    }

    .sec2-left-head, .sec3-left-head{
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
    }

    .sec2-left-desc, .sec3-left-desc{
        font-size: 18px;
    }

    .sec2-right, .sec3-right{
        width: 90vw;
        margin: auto;
        display: flex;
        justify-content: end;
    }

    .sec2-right-img, .sec3-right-img{
        height: 100px;
    }
}