.mob-menu-container{
    height: 100vh;
    width: 100vw;
    background-color: #18191D;
    padding-top: 100px;
}

.mob-menu-box{
    height: 60vh;
    width: 90vw;
    margin: auto;
    overflow-y: scroll;
}

.mob-menu-each{
    width: 90vw;
    padding: 20px;
    color:#FFF;
    border-radius: 10px;
    margin: 15px auto;
}

.mob-btns{
    width: 90vw;
    margin: auto;
}

.landing-btn1, .landing-btn2{
    width: 90vw;
    margin: auto;
    text-align: center;
    margin-top: 15px;
}