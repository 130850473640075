.nav-container{
    position: fixed;
    top:0px;
    display: flex;
    height: 70px;
    padding: 0px 2.5vw;
    justify-content: space-between;
    align-items: center;
    border-bottom: 8px solid #CF1A38;
    width: 100vw;
    overflow-x: hidden;
    background: #000;
}

.nav-left{
    height: 70px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.nav-logo-box{
    height: 70px;
    display: flex;
    align-items: center;
    transition: all ease .2s;
}

.nav-logo-box:hover{
    transform: scale(1.1);
}

.nav-logo{
    height: 40px;
}

.drop-box{
    background: transparent;
    outline: none;
    border-bottom: none;
    border-top: none;
    border-right: none;
    padding: 7px 0px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    border-left: 2px solid #CF1A38;
    cursor: pointer;
}

.drop-img-box{
    transition: all ease .2s;
}

.drop-menu-box{
    display: flex;
    align-items: center;
    transition: all ease .2s;
}

.drop-menu-box:hover{
    transform: scale(1.1);
}

.drop-menu{
    background: #000;
    margin-left: 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    border:none;
    outline:none;
    color: #FFFFFF;
    cursor: pointer;
}

.drop-menu-head{
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
}

.nav-right{
    height: 70px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.nav-menu-box{
    display: flex;
    align-items: center;
}

.nav-menu{
    width: 10vw;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: all ease .2s;
    text-align: center;
}

.nav-menu:hover{
    transform: scale(1.1);
}

.nav-buttons{
    height: 70px;
    display: flex;
    align-items: center;
}

.nav-btn1{
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    padding: 5px 20px;
    display: flex;
    margin-left: 20px;
    margin-right: 30px;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
    font-weight: 700;
    font-size: 19px;
    color: #FFFFFF;
    background: #000;
    outline: none;
    cursor: pointer;
}

.nav-btn1:hover{
    transform: scale(1.1);
}

.nav-btn1-img{
    margin-right: 5px;
}

.nav-btn2{
    background: linear-gradient(85.77deg, #CF1A38 -13.34%, #283991 131.26%);
    border-radius: 35px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.2s;
    font-weight: 700;
    font-size: 19px;
    color: #FFFFFF;
    outline: none;
    border: none;
    cursor: pointer;
}

.nav-btn2:hover{
    transform: scale(1.1);
}

.nav-ham-box{
    display: none;
}

@media only screen and (min-width:0px) and (max-width:900px) {
    .nav-menu-box, .nav-buttons{
        display: none;
    }

    .nav-ham-box{
        display: flex;
    }

    .nav-container{
        padding: 0px 5vw;
    }

    .nav-logo{
        height: 26px;
    }

    .drop-box{
        margin-left: 10px;
    }

    .drop-menu{
        margin-left: 10px;
    }
}