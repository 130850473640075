.mainLayout {
  .mainArea {
    &.true {
      .navbar {
        justify-content: center;
        .navBtn {
          display: none;
        }
      }
    }
  }
}
