.searchNavbar {
  height: 90px;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 70px;
  .crypto-img {
    cursor: pointer;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 0.4s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  .navSearch {
    display: flex;
    align-items: center;
    font-size: 20px;
    width: 82%;
    margin: 0;
    height: 100%;
    border-left: 1px solid $border-color;
    padding-left: 3%;
    .textInput {
      flex: 1;
      font-size: 16px;
      border: none;
      background: none;
      outline: none;
      display: flex;
      align-items: center;
      &::placeholder {
        text-transform: capitalize;
      }
    }
    .searchIcn {
      margin-right: 25px;
    }
    .icon {
      height: 100%;
      cursor: pointer;
      flex: 0 0 90px;
      width: 90px;
      border-left: 1px solid $border-color;
      display: flex;
      opacity: 0.3;
      img {
        margin: auto;
        width: 45%;
        height: 45%;
      }
      &:hover {
        * {
          transform: scale(1.05);
        }
      }
      &.true {
        opacity: 1;
        background: whitesmoke;
      }
    }
  }
  @include md {
    padding: 0 20px;
    .crypto-img {
      margin: 0 auto;
    }
  }
}
