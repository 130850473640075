.transactionCard {
  width: 65%;
  height: 75%;
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  .tabs {
    flex: 0 0 70px;
    border-bottom: 1px solid $border-color;
    display: flex;
    .tab {
      color: $primary-color;
      font-size: 19px;
      font-weight: 600;
      flex: 0 0 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease 300ms;
      &.true {
        color: black;
        border-bottom: 2px solid $primary-color;
      }
    }
  }
  .contents {
    padding: 0 30px;
    height: 0;
    flex: 1;
    color: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .group {
      .inpWraper {
        display: flex;
        border: 1px solid $border-color;
        height: 60px;
        overflow: hidden;
        margin-top: 10px;
        input {
          width: 0;
          flex: 1;
          border: none;
          padding: 0 30px;
          font-size: 20px;
        }
        .coin {
          min-width: 125px;
          height: 100%;
          padding: 0 20px;
          display: flex;
          align-items: center;
          border-left: 1px solid $border-color;
          img {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            margin-right: 10px;
          }
          span {
            font-size: 22px;
            font-weight: bold;
          }
          * {
            transition: all 300ms ease;
          }
          &.btns {
            cursor: pointer;
            &:hover {
              background: whitesmoke;
              * {
                transform: scale(1.03);
              }
            }
          }
        }
      }
    }
  }
  .ftBtns {
    flex: 0 0 70px;
    border-top: 1px solid $border-color;
    display: flex;
    .btFees,
    .btBuy {
      width: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 700;
      font-size: 19px;
      transition: all 400ms ease;
      &:hover {
        background: whitesmoke;
        font-size: 20px;
      }
    }
    .btBuy {
      border-left: 1px solid $border-color;
    }
  }
}
